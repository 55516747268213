import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import PickupForm, { PickupFormData, formValidation } from 'components/aio/PickupForm';
import SeparatedProductsContainer from 'containers/SeparatedProductsContainer';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import StorePreview from 'components/aio/StorePreview';
import AlertBadge from 'components/common/AlertBadge';

import { format as formatRUT } from 'rut.js';
import { setPickupForm, setStore } from 'store/store';
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { PickupStoreStockIcon } from 'components/pickup/PickupStoreStockIcon/PickupStoreStockIcon';
import { PickupStoreForm } from 'components/pickup/PickupStore/interface';

import { PE, RT, country } from 'consts';
import { formatBusinessHours, formatStoreAddress, formatStoreName } from 'lib/utils/pickup';
import {
    separateProducts as deleteProducts,
    updateSeparatedProducts,
} from 'store/separatedProducts';

import { getPickupSchedule, setSchedule } from 'store/pickup';
import { formatDate } from 'lib/utils/date';
import { useAnalytics } from 'lib/hooks/analytics';
import { fetchCartById } from 'store/cart';
import { changeDeliveryMethod } from 'store/deliveryMethod';
import { resetPayments } from 'store/paymentMethod/actions';
import { RootState } from 'types/store';
import { sendErrorToGA4 } from 'lib/utils/checkoutErrors';

export type StoreModalContainerProps = {
    isOpen: boolean;
    onToggle: () => void;
    onContinue: () => void;
};

export const StoreModalContainer: React.FC<StoreModalContainerProps> = (
    props: StoreModalContainerProps,
) => {
    const analytics = useAnalytics();
    const dispatch = useAppDispatch();

    const store = useAppSelector(
        (state: RootState) => state.store.data?.byId[state.store.data?.provisional || ''],
    );
    const delivery = useAppSelector((state: RootState) => state.pickup);
    const channel = useAppSelector((state: RootState) => state.cart.data?.channel);
    const schedule = useAppSelector(getPickupSchedule);
    const deliveryPrice =
        schedule?.price.value && +schedule.price.value > 0 ? schedule?.price.value : undefined;

    const form = useForm<PickupFormData>({
        defaultValues: { checkbox: 'me' },
        resolver: yupResolver(formValidation() as any), // eslint-disable-line @typescript-eslint/no-explicit-any
    });

    const [showSeparatedProductsModal, setShowSeparatedProductsModal] = useState(false);
    const [error, setError] = useState<null | string>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (error) {
            sendErrorToGA4({
                channel: channel,
                error: error,
                section: 'DeliverySection',
                type: 'Checkout_presentational_error',
            });
        }
    }, [error]);

    const saveForm = async (data: PickupFormData) => {
        const payload: PickupStoreForm = {
            checkbox: data.checkbox as 'me' | 'third',
            userPhoneNumber: data.userPhoneNumber,
            userPhoneNumberFormatted: data.userPhoneNumberFormatted,
            firstName: data.firstName,
            lastName: data.lastName,
            nin: data.nin,
            phoneNumber: data.phoneNumber,
            phoneNumberFormatted: data.phoneNumberFormatted,
            ninFormatted: formatRUT(data.nin),
        };

        dispatch(setPickupForm(payload));
        await confirmStore();
    };

    const confirmStore = async () => {
        try {
            setLoading(true);

            const unavailable = await dispatch(updateSeparatedProducts()).unwrap();
            if (unavailable.length) {
                setShowSeparatedProductsModal(true);
                return;
            }
            const storeId = store?.id || '';
            await dispatch(setSchedule()).unwrap();
            await dispatch(resetPayments()).unwrap();
            await dispatch(fetchCartById()).unwrap();
            await dispatch(changeDeliveryMethod(RT));
            await dispatch(setStore(storeId));

            analytics.push('add_shipping_info');
            props.onContinue();
        } catch (e) {
            setLoading(false);
            setError('Hubo un problema configurando la agenda.');
        }
    };

    const separateProducts = () => {
        (async () => {
            await dispatch(deleteProducts());
            await dispatch(fetchCartById());
            await confirmStore();
            setShowSeparatedProductsModal(false);
        })();
    };

    const handleSubmit = (event: React.FormEvent) => {
        (async (event) => {
            const handler = form.handleSubmit(saveForm);
            await handler(event);
        })(event);
    };

    if (showSeparatedProductsModal) {
        return (
            <SeparatedProductsContainer
                onContinue={separateProducts}
                onClose={() => {
                    setShowSeparatedProductsModal(false);
                }}
            />
        );
    }

    return (
        <>
            {store && schedule && delivery && (
                <Modal
                    size="xs"
                    header="Retiro en tienda"
                    isOpen={props.isOpen}
                    onToggle={props.onToggle}
                    footer={
                        <>
                            {error && (
                                <AlertBadge
                                    theme="danger"
                                    timeout={5000}
                                    callback={() => setError(null)}
                                >
                                    {error}
                                </AlertBadge>
                            )}

                            <Button
                                theme="primary"
                                size="large"
                                loading={loading}
                                disabled={loading}
                                onClick={handleSubmit}
                            >
                                Continuar
                            </Button>
                        </>
                    }
                >
                    <>
                        <StorePreview
                            deliverDate={`Retira el ${formatDate(
                                new Date(schedule?.closestDate.slice(0, -6)),
                            )}`}
                            icon={
                                <PickupStoreStockIcon
                                    country={country}
                                    storeType={store.type}
                                    storeId={store.id}
                                />
                            }
                            linkTo={props.onToggle}
                            storeAddress={formatStoreAddress(store)}
                            storeBusinessHours={country == PE ? formatBusinessHours(store) : ''}
                            storeName={formatStoreName(store)}
                            deliveryPrice={deliveryPrice}
                        />

                        <PickupForm country={country} form={form} onSubmit={handleSubmit} />
                    </>
                </Modal>
            )}
        </>
    );
};
