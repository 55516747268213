import { RT, RC, DD, MP, DP } from 'consts';

export const getJornada = (shippingType?: string): string => {
    switch (shippingType) {
        case RT:
            return 'Retiro en Tienda';
        case RC:
            return 'Retiro Cercano';
        case DD:
            return 'Despacho a Domicilio';
        case MP:
            return 'Despacho Programado';
        case DP:
            return 'Despacho Programado';
        default:
            return 'Jornada no identificada';
    }
};

export const getTarjetaRipley = (paymentMethod?: PaymentMethodName): string => {
    switch (paymentMethod) {
        case 'Niubiz':
            return 'Otras Bancarias';
        case 'Alignet':
            return 'Tarjeta Ripley';
        case 'Orbis':
            return 'Pago Efectivo';
        case 'Niubiz3DS':
            return 'Yape';
        case 'RipleyCard':
            return 'Tarjeta Ripley';
        case 'ChekCard':
            return 'Tarjeta Ripley Chek';
        case 'WebpayDebito':
            return 'Webpay';
        case 'WebpayCredito':
            return 'Webpay_credit';
        case 'RipleyChek':
            return 'CHEK';
        case 'RipleyGiftCard':
            return 'GiftCard';
        default:
            return 'Método de pago no identificado';
    }
};

// Elimina duplicados en un array y aplica un join usando el string " | " como separador
export const joinArray = (array?: string[]) => {
    const uniqueArray = [...new Set(array)];
    const joinedArray = uniqueArray?.join(' | ');
    return joinedArray;
};
