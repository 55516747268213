import { getEnvironment } from 'config';

const { app } = getEnvironment();

/** Chile */
export const CL = 'CL';
/** Peru */
export const PE = 'PE';

/** Maps a locale to a 2-letter ISO country code. */
export const LOCALE_COUNTRY = {
    es_CL: 'CL' as Country,
    es_PE: 'PE' as Country,
};

/** 2-letter ISO code set from env var REACT_APP_LOCALE */
export const country = LOCALE_COUNTRY[app.locale as keyof typeof LOCALE_COUNTRY] || CL;
/** 3-letter ISO 4217 currency code set from env var REACT_APP_CURRENCY */
export const currency = app.currency;

/** Character used to mask a card number */
export const MASKED_PAN_SEPARATOR = '*';

/** Retiro en tienda */
export const RT = 'RT';

/** Retiro cercano */
export const RC = 'RC';

/** Despacho a domicilio */
export const DD = 'DD';

/** Schedule delivery methods */
export const MP = 'MP';

/** Despacho Programado */
export const DP = 'DP';

/** Prefix for typeOfService of marketplace products delivered by Ripley */
export const FULFILLMENT = 'FF';

/**
 * UX Definition:
 * - Despacho express: productos de 24 a 48 horas
 * - Despacho programado: cuando existe mas de una agenda y el cliente puede escoger la opción
 * - Despacho estándar: que es un rango de días
 * - Despacho digital: son los envíos por correo
 */
export const deliveryMethodDescription: deliveryMethodDescriptionType = {
    DP: 'Despacho Programado',
    MP: 'Despacho Programado',
    DE: 'Despacho Express',
    DS: 'Despacho Estándar',
};

// Compatible group selection
/** Used to select products from non-problematic delivery methods */
export const ANY_DELIVERY_METHOD = 'ANY_DELIVERY_METHOD';
/** Used to select shipping group by largest price */
export const LARGEST_PRICE_GROUP = 'LARGEST_PRICE_GROUP';

/**
 * Service types:
 * Logistic Engine returns this in the `deliveryDetails[i].typeOfService` field.
 * Transactions' cart returns this in the `shippingInfo[i].timing` field.
 */
export const SERVICE_TYPE = {
    STS: 'ST',
    RET_SELLER_CL: 'RET SELLER',
    RET_SELLER_PE: 'RET_SELLER',
    STANDARD: 'S',
};

export const SELLER_RIPLEY = '1';

/** Channel for Tienda virtual */
export const INTERNET = 'Tienda Virtual';
/** Channel for Kiosko */
export const KIOSKO = 'Kiosko';
/** Channel for mobile app */
export const MOBILE = 'Rapp'; /* TODO: get correct value */

/** Wait for querying cart for "pending" status change */
export const ALIGNET_STATUS_RETRY = 5000;
/** Max retries for cart "pending" status change */
export const ALIGNET_STATUS_MAX = 10;

export const IS_CHEK_OPEX = app.is_chek_opex;
export const OPEX_PAYMENT_METHODS: PaymentMethodName[] = IS_CHEK_OPEX
    ? ['RipleyCard', 'RipleyChek', 'Alignet', 'RipleyChekCard']
    : ['RipleyCard', 'Alignet', 'RipleyChekCard'];

export const vatPercentage = {
    CL: 0.19,
    PE: 0.18,
};

export const defaultUrls = {
    CL: {
        REACT_APP_BANK: '',
        REACT_APP_BOLSA_URL: '',
        REACT_APP_KIOSKO_LOGOUT_URL: '',
        REACT_APP_LOGIN_URL: '',
        REACT_APP_MI_CUENTA_URL: '',
        REACT_APP_NAVEGACION_URL: '',
        REACT_APP_TIENDA_URL: 'https://simple.ripley.cl',
    },
    PE: {
        REACT_APP_BANK: 'https://www.bancoripley.com.pe/',
        REACT_APP_BOLSA_URL: 'https://nav-simplest.ripley.com.pe/bolsa',
        REACT_APP_KIOSKO_LOGOUT_URL: 'https://nav-simplest-prd.ripley.cl/kiosko/login',
        REACT_APP_LOGIN_URL: 'https://navegacion-simplest-prod.ripley.cl/usuario/login',
        REACT_APP_MI_CUENTA_URL: 'https://mi-cuenta.ripley.com.pe',
        REACT_APP_NAVEGACION_URL: 'http://nav-simplest.ripley.com.pe',
        REACT_APP_TIENDA_URL: 'https://simple.ripley.com.pe',
    },
};

export const TERMS_AND_CONDITIONS_CL = '/terminos_y_condiciones_14_06_24.pdf';
export const TERMS_AND_CONDITIONS_PE = '/terminos_y_condiciones_web_peru.pdf';

/**
 * Returns an env URL with a default fallback value.
 * Raises a compile-time error if the key is not defined in defaultUrls
 */
export function URL(key: keyof typeof defaultUrls.CL): string {
    const url = process.env[key] || defaultUrls[country][key];
    if (!url) {
        throw `Value not defined for ${country}.${key}`;
    }
    return url;
}

export function getTermsAndConditionByCountry(country: Country): string {
    return country === 'CL' ? TERMS_AND_CONDITIONS_CL : TERMS_AND_CONDITIONS_PE;
}

export const SHOW_UNAVAILABLE_STORES = (app.showUnavailableStores ?? 'true') === 'true';

/**
 * Default areas
 */
export const DEFAULT_REGION_CL = '13'; // RM
export const DEFAULT_REGION_PE = '15'; // LIMA
export const DEFAULT_DISTRICT_PE = '1501'; // LIMA
export const POLITICS_AND_PRIVACY = '/politicas_de_privacidad_pe.pdf';
export const REDIRECT_FULFILLMENT = app.redirectFulfillment;
export const sellers_ids = app.sellersIds;

/**
 * CL
 *    Región = regionCode
 *    Comuna = localityCode
 * PE
 *    Departamento = regionCode
 *    Provincia = districtCode
 *    Distrito = localityCode
 */

export const UTM_ENABLE = true;

/**
 * Local app storage name
 *  */
export const STORAGE_PREFIX = 'persist:';
export const STORAGE_KEY = 'checkout-storage';

/**
 * cookies' ids
 *  */
export const COOKIE_CART_ID = 'NMKR_TRA';
export const COOKIE_CUSTOMER_TOKEN = 'RIPLEY_CUSTOMER_TOKEN';
export const COOKIE_CUSTOMER_REFRESH_TOKEN = 'RIPLEY_CUSTOMER_REFRESH_TOKEN';

export const ENDPOINTS_ERRORS: string[] = ['cts'];

export const ITEM_LIST_NAME = {
    GIFT: 'Regalos',
};
