import * as yup from 'yup';
import { validate as validateRUT, clean as cleanRUT } from 'rut.js';
import { trans } from 'lib/translator';
import { validarRucModulo11 } from 'components/payment/TaxInstrumentForm/TaxInstrumentForm';

const getErrorMsg = trans('validations');

export const ChileFormValidation = yup.object({
    phoneNumber: yup
        .string()
        .required(getErrorMsg('req'))
        .transform((value) => value.replace(/-/g, '').replace(/_/g, '')),
    nin: yup
        .string()
        .required(getErrorMsg('req'))
        .transform((value) => cleanRUT(value))
        .test('nin', 'El RUT no es válido', (value) => validateRUT(value || '')),
    companyName: yup
        .string()
        .required(getErrorMsg('req'))
        .max(20, getErrorMsg('max', { num: 20 })),
    activityCode: yup.string().required(getErrorMsg('req')),
    activity: yup.string().required(getErrorMsg('req')),
    streetName: yup
        .string()
        .required(getErrorMsg('req'))
        .max(20, getErrorMsg('max', { num: 20 })),
    streetNumber: yup
        .string()
        .required(getErrorMsg('req'))
        .max(20, getErrorMsg('max', { num: 20 })),
    homeNumber: yup.string().max(20, getErrorMsg('max', { num: 20 })),
    regionCode: yup.string().required(getErrorMsg('req')),
    localityCode: yup.string().required(getErrorMsg('req')),
    region: yup.string(),
    locality: yup.string(),
});

export const PeruFormValidation = yup.object({
    companyName: yup
        .string()
        .required(getErrorMsg('req'))
        .max(128, getErrorMsg('max', { num: 128 })),
    nin: yup
        .string()
        .required(getErrorMsg('req'))
        .max(11, getErrorMsg('max', { num: 11 }))
        .transform((value) => cleanRUT(value))
        .test('nin', 'El RUC no es válido', (value) => validarRucModulo11(value || '')),
});
