import React from 'react';

// Datos a embeber
export type EmbeddedDataPropsData = {
    División?: string;
    SKU?: string;
    'Vendedor / Seller'?: string;
    'Unidad económica'?: string;
    'Tarjeta Ripley'?: string;
    'Zona de despacho'?: string;
    Jornada?: string;
    'Orden de Compra'?: string;
    'Canal de Venta'?: string;
    'Monto Compra'?: string;
    'Cantidad Item'?: string;
    'Tipo de sesión'?: string;
    'Fecha TRX'?: string;
    'Dispositivo usado'?: string;
    Ciudad?: string;
    País?: string;
    'Canal de compra'?: string;
};

export type EmbeddedDataProps = {
    data: EmbeddedDataPropsData;
};

/* Mapea cada elemento de props.data y lo devuelve como un input de 
type=hiden con id=key y value=value */
export const EmbeddedData: React.FC<EmbeddedDataProps> = ({ data }) => {
    return (
        <div style={{ display: 'none' }}>
            {Object.entries(data).map(([key, value]) => (
                <input type="hidden" id={key} value={value ?? ''} key={key} />
            ))}
        </div>
    );
};
