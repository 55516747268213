import { datadogRum } from '@datadog/browser-rum';

import json from '../../../package.json';
import { getEnvironment } from 'config';
import Cookies from 'js-cookie';

export const createDataDogReporter = (country: Country, isMobile: boolean): void => {
    const { dataDog } = getEnvironment();

    const { version } = json;

    const {
        availableDataDog,
        applicationId,
        clientToken,
        site,
        env,
        sessionReplaySampleRate,
        sessionSampleRate,
    } = dataDog;

    if (availableDataDog) {
        const service =
            env === 'prod'
                ? `ripley-checkout-${country}---one-step`.toLowerCase()
                : `checkout-${country}---${env}`.toLowerCase();

        datadogRum.init({
            applicationId,
            clientToken,
            site,
            service,
            env,
            version,
            sessionSampleRate,
            sessionReplaySampleRate,
            trackUserInteractions: true,
            defaultPrivacyLevel: 'mask-user-input',
        });

        const cartId = Cookies.get('NMKR_TRA') ?? '';
        const userId = Cookies.get('RIPLEY_CUSTOMER_ID') ?? '';
        const channel = isMobile ? 'app' : 'web';

        datadogRum.setUser({
            id: userId,
            cartId,
            channel,
        });

        datadogRum.startSessionReplayRecording();
    }
};
