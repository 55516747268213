import React, { useEffect } from 'react';
import { getEnvironment } from 'config';
import { qualtricsScript } from './QualtricsScript';

const QualtricsFeedback = () => {
    const env = getEnvironment();
    useEffect(() => {
        // Añadir el script al documento
        setTimeout(() => {
            document.body.appendChild(qualtricsScript);
        }, env.nps.surveyTime);

        // Limpieza al desmontar el componente
        return () => {
            document.body.removeChild(qualtricsScript);
        };
    }, [env.nps.surveyTime]); // Se ejecuta solo una vez al montar el componente

    return <div id="ZN_1dMoVtCJwLHVspW">{/* DO NOT REMOVE - CONTENIDOS PLACED HERE */}</div>;
};

export default QualtricsFeedback;
