import React, { useEffect } from 'react';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';

import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Route from './Route';

import { getEnvironment } from 'config';
import { AppState } from 'store/app/app.slice';
import { CartState } from 'store/cart/cart.slice';
import { CheckoutState } from 'store/checkout/checkout.slice';

import SidebarKiosko from 'containers/SidebarKioskoContainer';
import ErrorContainer from 'containers/aio/ErrorContainer';

import { initialize, extraInitialize, initializeOauth } from 'store/app';
import { ChannelContext } from 'components/aio/Layout';

// Pages
import Home from './pages/Home';
import CreateAddress from 'pages/CreateAddress';
import Checkout from './pages/Checkout';
import OrderSummary from 'pages/OrderSummary';
import Authorize from 'pages/Authorize';
import RedirectPage from 'pages/Redirect';
import { sendMessageToRapp } from 'lib/utils/mobile';

export default function App(): JSX.Element {
    const dispatch = useAppDispatch();
    const app = useAppSelector((s: { app: AppState }) => s.app);
    const cart = useAppSelector((s: { cart: CartState }) => s.cart);
    const checkout = useAppSelector((s: { checkout: CheckoutState }) => s.checkout);

    const {
        analytics,
        oAuth: { enable },
    } = getEnvironment();

    useEffect(() => {
        if (app.status === 'idle') {
            sendMessageToRapp('rendering');
            if (enable) {
                const oauthPromise = dispatch(initializeOauth());
                if (oauthPromise instanceof Promise) {
                    oauthPromise.then(() => {
                        dispatch(initialize());
                        dispatch(extraInitialize());
                    });
                }
            } else {
                dispatch(initialize());
                dispatch(extraInitialize());
            }
        }
    }, [dispatch, app, enable]);

    return (
        <GTMProvider state={{ id: analytics.gtmId || '' }}>
            <ChannelContext.Provider value={app.data?.channel ?? ''}>
                <Router>
                    <ErrorContainer>
                        <SidebarKiosko />
                        {cart.data?.status === 'complete' || checkout.status === 'ok' ? (
                            <Switch>
                                <Route path="/order-summary" component={OrderSummary} />
                                <Route path="*">
                                    <Redirect to="/order-summary" />
                                </Route>
                            </Switch>
                        ) : (
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route path="/create-address" component={CreateAddress} />
                                <Route path="/checkout" component={Checkout} />
                                <Route path="/authorize" component={Authorize} />
                                <Route path="/redirect" component={RedirectPage} />
                                <Route path="/order-summary" render={() => <Redirect to="/" />} />
                                <Route path="*">
                                    <Redirect to="/" />
                                </Route>
                            </Switch>
                        )}
                    </ErrorContainer>
                </Router>
            </ChannelContext.Provider>
        </GTMProvider>
    );
}
