import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import styles from './OrderSummaryContainer.module.scss';
import { useTranslation } from 'react-i18next';

// Redux
import { useAppDispatch, useAppSelector } from 'lib/hooks/redux';
import { useAnalytics } from 'lib/hooks/analytics';
import { logoutUser } from '../../store/user';

// Components
import PuntosGo from '../../components/common/PuntosGo';
import OrderSteps from '../../components/order/OrderSteps';
import PaymentSummary, { Total } from 'components/payment/PaymentSummary';
import DeliveryResume from 'components/shipping/DeliveryResume';
import Button from 'components/common/Button';
import InStoreClock from 'components/common/InStoreClock';
import RegisterMessage from 'components/common/RegisterMessage';
import Modal from 'components/common/Modal';
import RegisterUser from 'containers/RegisterUser';
import Link from 'components/common/Link';
import QualtricsFeedback from 'components/nps/QualtricsFeedback';

// utils
import {
    formatCustomerAddress,
    formatDeliveries,
    formatDetails,
    goPointsCalculator,
    getPayments,
} from './OrderSummaryContainer.utils';

import { getTotals } from 'containers/CartContainer/CartContainer.utils';
import { CL, RT } from 'consts';
import { getAddressProvinceName, getAddressRegionName } from 'lib/utils/addresses';
import { getSelectedStore } from 'store/store';
import { formatBusinessHours } from 'lib/utils/pickup';
import { AlertBadge } from 'components/common/AlertBadge/AlertBadge';
import { getEnvironment } from 'config';
import { RootState } from 'types/store';
import { fetchDeleteCtsByDocumentId } from 'store/events/events.slice';
import EmbeddedDataContainer from 'containers/nps/EmbeddedDataContainer';

export const OrderSummaryContainer: React.FunctionComponent = () => {
    const envs = getEnvironment();
    const dispatch = useAppDispatch();

    const analytics = useAnalytics();
    const { ctsData } = useAppSelector((state: RootState) => state.events);
    const cart = useAppSelector((state: RootState) => state.cart);
    const user = useAppSelector((state: RootState) => state.user);
    const areas = useAppSelector((state: RootState) => state.area.data);
    const { selected } = useAppSelector((state: RootState) => state.deliveryMethod);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [createUserClicked, setCreateUserClicked] = useState(false);
    const country = (envs.app.country?.toUpperCase() as Country) || CL;
    const totalPrice = String(
        cart.data?.paymentInfo?.reduce((acc, payment) => (acc += +payment.amount.value), 0) || '',
    );
    const paymentMethods = useAppSelector((state: RootState) => state.paymentMethod);
    const mainShipping = cart?.data?.shippingInfo?.[0];
    const addressId = useAppSelector(
        (state: RootState) =>
            state.address.data?.byId[String(mainShipping?.customerAddress.addressId)],
    );
    const selectedCard = useAppSelector(
        (state: RootState) => state.card.byId[state.card.selected]?.data,
    );
    const cardToken = useAppSelector(
        (state: RootState) => state.cardToken.data.byId[state.card.selected],
    );
    const province = addressId ? getAddressProvinceName(addressId, areas) : '';
    const region = addressId ? getAddressRegionName(addressId, areas) : '';
    const additionalAddress = country === 'PE' ? `${province}, ${region}` : '';
    const customerAddress = formatCustomerAddress(
        String(mainShipping?.type),
        cart.data?.shippingInfo?.[0].customerAddress || {},
    );

    const payments = getPayments(paymentMethods, selectedCard, cardToken);
    const customerEmail = user.data?.email;
    const cartTotals = getTotals(cart.data || ({} as Cart));
    const formattedTotals: Total[] = [];
    const { t } = useTranslation('format');
    const { t: translation } = useTranslation('translation');
    const store = useAppSelector(getSelectedStore);
    const storeBusinessHours = store && formatBusinessHours(store);

    const emailStyle = {
        fontWeight: 700,
    };

    const paymentType = selectedCard?.maskedPan.includes('522788')
        ? 'RipleyCardBlack'
        : cart.data?.paymentInfo?.[0].paymentMethod;

    const goPoints = goPointsCalculator(totalPrice, paymentType || '');
    const isGuestUser = user.data?.registered == false;
    const canSignup = user.data?.canSignup;
    const deliveries = formatDeliveries(cart.data || ({} as Cart), t);
    const details = formatDetails(payments, selectedCard);
    const navURL = envs.app.tiendaUrl;
    const accountURL = envs.app.miCuentaUrl;
    const isInStore = paymentMethods.data?.selected.includes('InStore');

    const sessionId = Cookies.get('RIPLEY_CUSTOMER_SESSION');

    useEffect(() => {
        analytics.push('purchase');
        if (sessionId) {
            Cookies.remove('RIPLEY_CUSTOMER_SESSION');
        }

        (async () => {
            if (ctsData.length > 0 && envs.analytics.ctsEnabled) {
                await dispatch(fetchDeleteCtsByDocumentId());
            }
        })();
    }, []);

    if (isInStore) {
        Object.entries(cartTotals).forEach(([type, amount]) => {
            formattedTotals.push({
                type,
                amount: t('amount', { value: amount }),
            } as Total);
        });
    } else {
        formattedTotals.push({
            type: 'paid',
            amount: t('amount', { value: totalPrice }),
        } as Total);
    }

    return (
        <div>
            {cart.data?.channel === 'Kiosko' ? (
                <React.Fragment>
                    {isInStore && (
                        <div className={styles['timer']}>
                            <InStoreClock />
                        </div>
                    )}

                    <div className={styles['text-center']}>
                        <p>
                            Te enviaremos los detalles de tu compra a{' '}
                            <span style={emailStyle}>{customerEmail}</span>
                        </p>

                        <Button theme="primary" size="medium" onClick={logoutUser}>
                            Cerrar Sesión
                        </Button>
                    </div>

                    <PaymentSummary totals={formattedTotals} details={details} />

                    <br></br>

                    <DeliveryResume
                        address={customerAddress}
                        additionalAddress={additionalAddress}
                        deliveries={deliveries}
                        storeBusinessHours={storeBusinessHours}
                    />

                    <br></br>

                    <div className={styles['text-center']}>
                        <Button theme="secondary" size="medium" onClick={logoutUser}>
                            Cerrar Sesión
                        </Button>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {!isGuestUser && (
                        <>
                            <OrderSteps shippingType={'shipping'} country={'cl'} activeSteps={1} />
                            <div className={styles['text-center']}>
                                <p>
                                    Revisa el estado de tu compra en{' '}
                                    <Link to={accountURL} title="Mi cuenta">
                                        Mi cuenta
                                    </Link>
                                </p>
                            </div>

                            <br></br>
                            <PuntosGo points={goPoints} />
                            <br></br>
                        </>
                    )}

                    {selected === RT && country === CL && (
                        <div>
                            <AlertBadge theme="success">
                                No olvides llevar tu bolsa reutilizable cuando retires tus productos
                                en tienda.
                            </AlertBadge>
                        </div>
                    )}

                    <div className={styles['text-center']}>
                        <p>
                            Te enviaremos los detalles de tu compra a{' '}
                            <span style={emailStyle}>{customerEmail}</span>
                        </p>
                        {!isGuestUser && (
                            <Button
                                theme="primary"
                                size="medium"
                                onClick={() => {
                                    window.location.href = navURL;
                                }}
                            >
                                Volver a Ripley
                            </Button>
                        )}
                    </div>
                    <br></br>
                    {createUserClicked ? (
                        <RegisterMessage status={user.status} />
                    ) : (
                        isGuestUser &&
                        canSignup && (
                            <>
                                <div className={styles['register-user']}>
                                    <div className={styles['title']}>
                                        <b>Compra rápido</b> con cuenta Ripley
                                    </div>
                                    <div className={styles['content']}>
                                        <ul>
                                            <li>
                                                Usa tus <b>tarjetas</b> y{' '}
                                                <b>direcciones favoritas.</b>
                                            </li>
                                            <li>
                                                Accede al seguimiento e historial de compras y
                                                boletas.
                                            </li>
                                            <li>
                                                <b>Acumula Puntos Go,</b> accede a{' '}
                                                <b>descuentos y ¡más!</b>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className={styles['button']}>
                                        <Button
                                            theme="primary"
                                            size="medium"
                                            onClick={() => {
                                                setIsFormOpen(true);
                                            }}
                                        >
                                            {translation('createAccount')}
                                        </Button>
                                    </div>
                                </div>
                                <Modal
                                    header={
                                        <div className={styles['title-modal']}>
                                            <b>¡Crea tu cuenta Ripley!</b>
                                        </div>
                                    }
                                    isOpen={isFormOpen}
                                    onToggle={() => {
                                        setIsFormOpen(false);
                                    }}
                                >
                                    <RegisterUser
                                        setIsFormOpen={setIsFormOpen}
                                        setCreateUserClicked={setCreateUserClicked}
                                    />
                                </Modal>
                            </>
                        )
                    )}

                    <PaymentSummary totals={formattedTotals} details={details} />

                    <br></br>
                    <DeliveryResume
                        address={customerAddress}
                        additionalAddress={additionalAddress}
                        deliveries={deliveries}
                        storeBusinessHours={storeBusinessHours}
                    />
                    <br></br>
                    <div className={styles['text-center']}>
                        <Button
                            theme="secondary"
                            size="medium"
                            onClick={() => {
                                window.location.href = navURL;
                            }}
                        >
                            Volver a Ripley
                        </Button>
                    </div>
                </React.Fragment>
            )}
            {cart.data?.channel === 'Tienda Virtual' && envs.nps.quealtrics && (
                <>
                    <QualtricsFeedback />
                    <EmbeddedDataContainer />
                </>
            )}
        </div>
    );
};
