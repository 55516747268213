import React, { FormEvent, useEffect } from 'react';
import styles from './PickupForm.module.scss';

import { UseFormReturn } from 'react-hook-form';
import { PE } from 'consts';

import questionIcon from './asset/question.svg';
import RadioButton from 'components/common/RadioButton';
import PhoneField from 'components/common/PhoneField';
import FieldWrapper from 'components/common/FieldWrapper';
import TextField from 'components/common/TextField';
import { useRules } from 'lib/hooks/rules';

export type PickupFormData = {
    checkbox: string;
    firstName: string;
    lastName: string;
    nin: string;
    ninFormatted: string;
    phoneNumber: string;
    phoneNumberFormatted: string;
    userPhoneNumber: string;
    userPhoneNumberFormatted: string;
};

export type PickupFormProps = {
    country?: 'CL' | 'PE';
    form: UseFormReturn<PickupFormData>;
    onSubmit: (event: FormEvent) => void;
};

export const PickupForm: React.FC<PickupFormProps> = ({
    country,
    form,
    onSubmit,
}: PickupFormProps) => {
    const rules = useRules();
    const isEnabledThirdPartyPickup = rules.feature('thirdPartyPickup');
    const option = form.watch('checkbox');
    const errors = form.formState.errors;
    const values = form.formState.defaultValues;
    const setValue = form.setValue;

    useEffect(() => {
        if (option == 'me') {
            setValue('firstName', '');
            setValue('lastName', '');
            setValue('nin', '');
            setValue('phoneNumber', '');
        }
        if (option == 'third') {
            setValue('userPhoneNumber', '');
        }
    }, [option, setValue]);

    return (
        <form className={styles['pickup-form']} onSubmit={onSubmit}>
            <div className={styles['pickup-form-title']}>¿Quién retirará tu compra?</div>
            <div className={styles['pickup-form-radio']}>
                <RadioButton
                    id="me"
                    value="me"
                    variant="filled"
                    checked={option == 'me'}
                    className={styles['radio-button']}
                    {...form.register('checkbox')}
                >
                    Yo
                </RadioButton>

                <RadioButton
                    id="third"
                    value="third"
                    variant="filled"
                    checked={option == 'third'}
                    disabled={!isEnabledThirdPartyPickup}
                    className={styles['radio-button']}
                    {...form.register('checkbox')}
                >
                    Un tercero
                    {!isEnabledThirdPartyPickup && (
                        <div className={styles['tooltip']}>
                            <img src={questionIcon} alt={'Icono Pregunta'} />
                            <div className={styles['bottom']}>
                                Opción no disponible por el momento.
                                <i className={styles['question-mark-icon']}></i>
                            </div>
                        </div>
                    )}
                </RadioButton>
            </div>
            {option == 'me' ? (
                <div className={styles['pickup-form-group']}>
                    <FieldWrapper error={errors.userPhoneNumber?.message}>
                        <PhoneField
                            label="Teléfono"
                            country={country}
                            defaultValue={values?.userPhoneNumber}
                            onChangeMasked={(_, formatted) => {
                                form.setValue('userPhoneNumberFormatted', formatted);
                            }}
                            isInvalid={errors.userPhoneNumber ? true : false}
                            {...form.register('userPhoneNumber')}
                        />
                    </FieldWrapper>
                </div>
            ) : (
                <div className={styles['pickup-form-group']}>
                    <div className={styles['pickup-form-subtitle']}>
                        Ingresa los datos del tercero:
                    </div>

                    <FieldWrapper
                        className={styles['pickup-form-control']}
                        error={errors.firstName?.message}
                    >
                        <TextField
                            label="Nombre"
                            placeholder="Nombre"
                            defaultValue={values?.firstName}
                            isInvalid={errors.firstName ? true : false}
                            {...form.register('firstName')}
                        />
                    </FieldWrapper>

                    <FieldWrapper
                        className={styles['pickup-form-control']}
                        error={errors.lastName?.message}
                    >
                        <TextField
                            label="Apellido"
                            placeholder="Apellido"
                            defaultValue={values?.lastName}
                            isInvalid={errors.lastName ? true : false}
                            {...form.register('lastName')}
                        />
                    </FieldWrapper>

                    <FieldWrapper
                        className={styles['pickup-form-control']}
                        error={errors.nin?.message}
                    >
                        <TextField
                            label={country === PE ? 'Dni' : 'Rut'}
                            placeholder={country === PE ? '' : '__.___.___-_'}
                            defaultValue={values?.nin}
                            isInvalid={errors.nin ? true : false}
                            {...form.register('nin')}
                        />
                    </FieldWrapper>

                    <FieldWrapper
                        className={styles['pickup-form-control']}
                        error={errors.phoneNumber?.message}
                    >
                        <PhoneField
                            label="Teléfono"
                            country={country}
                            defaultValue={values?.phoneNumber}
                            onChangeMasked={(_, formatted) => {
                                form.setValue('phoneNumberFormatted', formatted);
                            }}
                            isInvalid={errors.phoneNumber ? true : false}
                            {...form.register('phoneNumber')}
                        />
                    </FieldWrapper>
                </div>
            )}
        </form>
    );
};
