export const getEnvironment = (): Environment => {
    const {
        REACT_APP_AVAILABLE_DATADOG,
        REACT_APP_STAGE,
        REACT_APP_ID_DATADOG,
        REACT_APP_TOKEN_DATADOG,
        REACT_APP_SITE_DATADOG,
        REACT_APP_SESSION_REPLAY_SAMPLE_RATE_DATADOG,
        REACT_APP_SESSION_SAMPLE_RATE_DATADOG,
        REACT_APP_GTM_ID,
        REACT_APP_BFF_BASE_URL,
        REACT_APP_OAUTH_ENABLED,
        REACT_APP_KIOSKO_LOGOUT_URL,
        REACT_APP_REACT_APP_DELIVERY_METHODS,
        REACT_APP_CART_ID_COOKIE,
        REACT_APP_CART_ID_QUERY_PARAM,
        REACT_APP_MAX_INSTALLMENTS_RIPLEY,
        REACT_APP_LOCALE,
        NODE_ENV,
        REACT_APP_COUNTRY,
        RIPLEY_BIN_LIST,
        RIPLEY_CREDIT_CARD_BIN_LIST,
        REACT_APP_GA4_ID,
        REACT_APP_BOLSA_URL,
        REACT_APP_TIENDA_URL,
        REACT_APP_MI_CUENTA_URL,
        REACT_APP_HELP_WHATSAPP,
        REACT_APP_HELP,
        REACT_APP_LOGIN_URL,
        REACT_APP_BANK,
        REACT_APP_INSTORE_HOURS_TO_PAY,
        REACT_APP_CURRENCY,
        REACT_APP_SHOW_UNAVAILABLE_STORES,
        REACT_APP_REDIRECT_FULFILLMENT,
        REACT_APP_SELLERS_ID,
        REACT_APP_IS_CHEK_OPEX,
        REACT_APP_BIOMETRIC_VERIFICATION_TIMEOUT,
        REACT_APP_IS_ACTIVE_LOG_SENDING,
        REACT_APP_IS_ACTIVE_ANALYTIC_ERROR_EVENT,
        REACT_APP_IS_ACTIVE_ERROR_ID,
        REACT_APP_CTS_ENABLED,
        REACT_APP_IS_ACTIVE_SEND_ERROR_TO_GA4,
        REACT_APP_ENABLE_BANNER_PROMO,
        REACT_APP_QUALTRICS,
        REACT_APP_NPS_TIME,
    } = process.env;

    const getUrl = () => {
        if (REACT_APP_STAGE === 'prod') return undefined;
        const params = new URLSearchParams(window.location.search);
        const baseUrl = params.get('baseUrl');
        const urlRegex = /^(https):\/\/[^ "]*ripley\.(com|cl|com\.pe)(\/[^ ]*)?$/;
        if (baseUrl && urlRegex.test(baseUrl.trim())) {
            return baseUrl;
        }
        return undefined;
    };

    const getOauth = () => {
        if (REACT_APP_STAGE === 'prod') return false;
        const params = new URLSearchParams(window.location.search);
        const oauth = params.get('oauth');
        if (oauth === 'true') return true;
        return false;
    };

    const environment = {
        dataDog: {
            availableDataDog: REACT_APP_AVAILABLE_DATADOG === 'false' ? false : true,
            applicationId: REACT_APP_ID_DATADOG ?? '',
            clientToken: REACT_APP_TOKEN_DATADOG ?? '',
            site: REACT_APP_SITE_DATADOG,
            env: REACT_APP_STAGE,
            sessionSampleRate: parseFloat(REACT_APP_SESSION_SAMPLE_RATE_DATADOG ?? '0.5'),
            sessionReplaySampleRate: parseInt(REACT_APP_SESSION_REPLAY_SAMPLE_RATE_DATADOG ?? '50'),
        },
        analytics: {
            gtmId: REACT_APP_GTM_ID || '',
            ga4Id: REACT_APP_GA4_ID,
            ctsEnabled: REACT_APP_CTS_ENABLED === 'yes',
        },
        bff: {
            baseUrl: getUrl() ?? REACT_APP_BFF_BASE_URL,
        },
        oAuth: {
            enable: getOauth() || REACT_APP_OAUTH_ENABLED === 'yes',
        },
        kiosko: {
            logoutUrl: REACT_APP_KIOSKO_LOGOUT_URL,
        },
        delivery: {
            methods: REACT_APP_REACT_APP_DELIVERY_METHODS || 'RT,DD',
        },
        cart: {
            idCookie: REACT_APP_CART_ID_COOKIE || 'NMKR_TRA',
            queryParam: REACT_APP_CART_ID_QUERY_PARAM || 'cartId',
        },
        payments: {
            maxInstallmentsRipley: REACT_APP_MAX_INSTALLMENTS_RIPLEY || '48',
            ripleyBinList: RIPLEY_BIN_LIST || '549070,522788,525384,639229,628156',
            ripleyCreditCardBinList: RIPLEY_CREDIT_CARD_BIN_LIST || '628156,549070,522788',
            bank: REACT_APP_BANK,
            inStoreHoursToPay: REACT_APP_INSTORE_HOURS_TO_PAY || '24',
        },
        nps: {
            quealtrics: REACT_APP_QUALTRICS === 'true',
            surveyTime: parseInt(REACT_APP_NPS_TIME ?? '3000'),
        },
        app: {
            locale: REACT_APP_LOCALE,
            env: NODE_ENV,
            country: REACT_APP_COUNTRY,
            bolsaUrl: REACT_APP_BOLSA_URL || 'https://simple.ripley.cl/bolsa',
            tiendaUrl: REACT_APP_TIENDA_URL || 'https://simple.ripley.cl',
            miCuentaUrl: REACT_APP_MI_CUENTA_URL || 'https://mi-cuenta.ripley.cl/',
            helpWhatsApp: REACT_APP_HELP_WHATSAPP,
            help: REACT_APP_HELP,
            loginUrl: REACT_APP_LOGIN_URL,
            currency: REACT_APP_CURRENCY || 'CLP',
            showUnavailableStores: REACT_APP_SHOW_UNAVAILABLE_STORES,
            redirectFulfillment: REACT_APP_REDIRECT_FULFILLMENT ?? '',
            sellersIds: REACT_APP_SELLERS_ID?.split(',') ?? [],
            is_chek_opex: REACT_APP_IS_CHEK_OPEX === 'no' ? false : true,
            biometricVerificationTimeout: parseInt(
                REACT_APP_BIOMETRIC_VERIFICATION_TIMEOUT ?? '30000',
            ),
            isActiveLogSending: REACT_APP_IS_ACTIVE_LOG_SENDING !== 'no',
            isActiveErrorId: REACT_APP_IS_ACTIVE_ERROR_ID !== 'no',
            isActiveAnalyticErrorEvent: REACT_APP_IS_ACTIVE_ANALYTIC_ERROR_EVENT !== 'no',
            isActiveSendErrorToGA4: REACT_APP_IS_ACTIVE_SEND_ERROR_TO_GA4 !== 'no',
            enableBannerPromo: REACT_APP_ENABLE_BANNER_PROMO === 'yes',
        },
    };

    return environment;
};
