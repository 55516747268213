import React from 'react';
import EmbeddedData, { EmbeddedDataPropsData } from 'components/nps/EmbeddedData';
import { useAppSelector } from 'lib/hooks/redux';
import { RootState } from 'types/store';
import { getJornada, getTarjetaRipley, joinArray } from './EmbeddedDataContainer.utils';

export const EmbeddedDataContainer: React.FC = () => {
    const user = useAppSelector((state: RootState) => state.user.data);
    const cart = useAppSelector((state: RootState) => state.cart.data);
    const paymentInfo = cart?.paymentInfo?.[0];
    const shippingInfo = cart?.shippingInfo?.[0];
    let cantidadItem = 0;
    cart?.products.forEach((prod) => {
        cantidadItem += prod.quantity;
    });
    const zonaDeDespacho = `${shippingInfo?.customerAddress['region']}-${shippingInfo?.customerAddress['state']}`;
    let tipoDeSesion = '';
    if (typeof tipoDeSesion === 'boolean') {
        tipoDeSesion = user?.registered ? 'Registered' : 'Guest';
    }

    const jornada = getJornada(shippingInfo?.type);
    const tarjetaRipley = getTarjetaRipley(paymentInfo?.paymentMethod);
    const SKU = joinArray(cart?.products?.map((p) => p.details.seller));
    const seller = joinArray(cart?.products?.map((p) => p.details.seller));
    const division = joinArray(cart?.products?.map((p) => p.details.department));
    const now = new Date();
    const fechaTrx = now.toLocaleString();
    const unidadEconómica = joinArray(cart?.products.map((p) => p.details.economicUnit));

    const embeddedData: EmbeddedDataPropsData = {
        División: division,
        SKU: SKU,
        'Vendedor / Seller': seller,
        'Unidad económica': unidadEconómica,
        'Tarjeta Ripley': tarjetaRipley,
        'Zona de despacho': zonaDeDespacho,
        Jornada: jornada,
        'Orden de Compra': cart?.orderId,
        'Canal de Venta': cart?.channel,
        'Monto Compra': paymentInfo?.amount.value,
        'Cantidad Item': String(cantidadItem),
        'Tipo de sesión': tipoDeSesion,
        'Fecha TRX': fechaTrx,
        'Dispositivo usado': 'Desktop',
        Ciudad: shippingInfo?.customerAddress['city'],
        País: cart?.country,
        'Canal de compra': 'WEB',
    };
    return <EmbeddedData data={embeddedData} />;
};
