import React from 'react';
import styles from './PromoBanner.module.scss';

export const PromoBanner: React.FC = () => {
    return (
        <div className={styles.banner}>
            <p>
                ¡Aprovecha <span className={styles.highlight}>3 cuotas sin interés</span> en todas
                tus compras con <span className={styles.bold}>Tarjeta Ripley</span>!{' '}
                <span className={styles.logo}>
                    <img alt="Ripley" />
                </span>
            </p>
        </div>
    );
};
