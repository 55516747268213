import Cookies from 'js-cookie';
import { RappDataMessage, RappMessage } from 'types/analytics';

export const sendMessageToRapp = (stage: string): void => {
    if (window?.ReactNativeWebView?.postMessage) {
        const query = new URLSearchParams(window.location.search);
        const deviceId = query.get('deviceId') ?? '';
        const channel = query.get('channel') ?? '';
        const appSessionId = query.get('appSessionId') ?? '';
        const cartId = Cookies.get('NMKR_TRA') ?? '';
        const userId = Cookies.get('RIPLEY_CUSTOMER_ID') ?? '';
        const ripleyCustomerToken = Cookies.get('RIPLEY_CUSTOMER_TOKEN') ?? '';
        const ripleyCustomerRefreshToken = Cookies.get('RIPLEY_CUSTOMER_REFRESH_TOKEN') ?? '';
        const utmSource = query.get('utmSource') ?? '';
        const utmCampaign = query.get('utmCampaign') ?? '';
        const utmMedium = query.get('utmMedium') ?? '';

        const rappDataMessage: RappDataMessage = {
            queryParams: {
                channel,
                deviceId,
                appSessionId,
                utmSource,
                utmCampaign,
                utmMedium,
            },
            cookies: {
                NMKR_TRA: cartId,
                RIPLEY_CUSTOMER_ID: userId,
                RIPLEY_CUSTOMER_TOKEN: ripleyCustomerToken.length > 0,
                RIPLEY_CUSTOMER_REFRESH_TOKEN: ripleyCustomerRefreshToken.length > 0,
            },
            meta: {
                stage,
                timestamp: Date.now(),
            },
        };

        const rappMessage: RappMessage = {
            type: 'healthcheck',
            data: rappDataMessage,
        };

        window.ReactNativeWebView.postMessage(JSON.stringify(rappMessage));
    }
};
