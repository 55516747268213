import jsonata from 'jsonata';

export const normalizeCart = (cart: ExtCart): Cart => {
    /**
     * https://try.jsonata.org/hFOaGT98a
     */
    const schema = `{
        "id": id,
        "channel": channel,
        "type": type,
        "status": status,
        "country": country,
        "orderId": orderId,
        "coupons": coupons ? coupons : [],
        "couponsInfo": couponsInfo ? couponsInfo : [],
        "discounts": discounts,
        "products": [products.{
            "id": id,
            "sku": sku,
            "skuPmm": skuPMM,
            "type": type,
            "quantity": quantity,
            "isGift": isGift,
            "skuGift": skuGift ? skuGift : [],
            "prices": prices{
                $lowercase(type):{
                    "currency": currency,
                    "type": type,
                    "value": value
                }
            },
            "details": details{
                "name": name,
                "brand": brand,
                "department": department,
                "seller": seller,
                "sellerId": sellerId,
                "thumbnail": thumbnail,
                "attributes": [attributes]
            },
            "related": related.id ? [related.{
                "id": id,
                "sku": sku,
                "type": type,
                "quantity": quantity,
                "prices": prices{
                    $lowercase(type):{
                        "currency": currency,
                        "type": type,
                        "value": value
                    }
                },
                "details": details{
                    "name": name,
                    "brand": brand,
                    "department": department,
                    "seller": seller,
                    "sellerId": sellerId,
                    "thumbnail": thumbnail,
                    "attributes": [attributes],
                    "economicUnit": economicUnit
                }
            }] : null 
        }],
        "grandTotal": grandTotal{
                $lowercase(type):{
                    "currency": currency,
                    "type": type,
                    "value": value
                }
        },
        "shippingInfo":shippingInfo,
        "paymentInfo":paymentInfo,
        "kiosko": kiosko
    }`;
    return jsonata(schema).evaluate(cart) as any;
};
